//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import project from '~/static/config/project.json'
import { countryData, countryLocation } from '~/lib/countries'
import global from '~/mixins/global.js'
export default {
  mixins: [global],
  props: {
    versions: {
      type: Array,
      default: () => []
    }
  },
  data () {
    return {
      sourceCountry: null,
      targetCountry: null,
      countrySelected: 0
    }
  },
  async mounted () {
    this.countrySelected = sessionStorage.getItem('countrySelected')
    countryLocation.then((location) => {
      if (project.country === location.country) {
        this.targetCountry = countryData(project.id)
      } else {
        if (project.subprojects) {
          const subproject = project.subprojects.find((subproject) => {
            return subproject.country === location.country
          })
          if (subproject) {
            this.targetCountry = countryData(subproject.id)
            this.targetCountry.path = this.$route.path
          }
        } else {
          this.targetCountry = countryData(location.country, 'iso')
          if (this.targetCountry && (this.versions.length > 0)) {
            const version = this.versions.find((version) => {
              return ((version.lang === this.targetCountry.lang) || (version.lang === this.targetCountry.lang + '-' + this.targetCountry.iso))
            })
            if (version) {
              this.targetCountry.path = version.url.replace(/^https?:\/\/[^/]*/, '')
            }
          }
        }
      }
    })
    this.sourceCountry = countryData(this.$store.state.countries.countryProject)
  },
  methods: {
    countryChange () {
      if (this.targetCountry?.path) {
        sessionStorage.setItem('countrySelected', 1)
        const query = this.$route.query
        query.path = this.targetCountry.path
        window.location.href = this.targetCountry.url + this.$router.resolve({ path: '/countries/' + this.targetCountry.id, query, hash: this.$route.hash }).href
      } else {
        this.countrySelect()
      }
    },
    countrySelect () {
      sessionStorage.setItem('countrySelected', 1)
      this.countrySelected = 1
    }
  }
}
